import { CACHE_KEY_TAGS } from 'configs';
import {
  getDurationInSeconds,
  Logger,
} from 'utils';
import { State } from '@/config/state';
import { type FilterGroup } from '@/types/list-page';

const useFilterScaffold = () => {
  const { locale } = useI18n();

  const GqlInstance = useGql();

  const {
    fetchProductData,
    getProductData,
  } = useProductData();

  const filterScaffold = useState<FilterGroup[]>(State.FilterScaffold, () => []);

  const getFilterScaffold = computed(() => filterScaffold.value);

  const setFilterScaffold = async () => {
    if (getFilterScaffold.value.length) {
      return;
    }

    await fetchProductData();

    const {
      addToCache,
      value: cachedFilterGroups,
    } = await useDataCache<FilterGroup[]>(`design-filters-${locale.value}`);

    if (cachedFilterGroups) {
      filterScaffold.value = cachedFilterGroups;
      return;
    }

    try {
      const { designFilters } = await GqlInstance('DesignFilters', { localeCode: locale.value });
      // Get merch product data and add size filter to initialFilters

      if (designFilters) {
        const newFilterScaffold = [
          ...(designFilters as FilterGroup[])

            .map((filterGroup) => ({
              ...filterGroup,
              filters: filterGroup.filters.map((filter) => ('subItems' in filter
                ? {
                  ...filter,
                  subItems: filter.subItems?.sort((a, b) => (a.label && b.label
                    ? a.label.localeCompare(b.label)
                    : 0)),
                }
                : filter)).sort((a, b) => (a.label && b.label
                ? a.label.localeCompare(b.label)
                : 0)),
            })),
          {
            filters: getProductData.value.map(((
              product,
            ) => product.color.values.map((color) => color) ?? []))
              .flat()
            // filter out duplicates
              .filter((value, index, array) => array.indexOf(value) === index)
              .map((color) => ({ key: color })),
            key: 'productColor',
          },
        ] as FilterGroup[];

        filterScaffold.value = newFilterScaffold;

        addToCache(
          newFilterScaffold,
          [
            CACHE_KEY_TAGS.NUXT_MULTI_CACHE_DEFAULT,
            CACHE_KEY_TAGS.NUXT_MULTI_CACHE_DESIGN_FILTERS,
          ],
          getDurationInSeconds({ hours: 1 }),
        );
      }
    } catch (_error) {
      Logger.error('Failed to fetch design filters', _error);
    }
  };

  return {
    getFilterScaffold,
    setFilterScaffold,
  };
};

export { useFilterScaffold };
